import React from "react";

import config from "/src/config.js";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  const pathname = typeof window !== "undefined" ? window.location.pathname.replace(/\//g, "") : "";
  const shouldRedirect =
    typeof window !== "undefined" ? config.LOCALES_SUPPORTED_ARRAY.includes(pathname) || !pathname : false;

  if (typeof window !== "undefined") {
    console.log("Home pathname", config.LOCALES_SUPPORTED_ARRAY, pathname, shouldRedirect);
  }

  return (
    <Helmet title="Book A Corner"> {shouldRedirect && <meta httpEquiv="refresh" content="0; url=./home/" />}</Helmet>
  );
};

export default IndexPage;
